<template>
  <div>
    <div class="flex items-center justify-between mb-10">
      <h3 class="text-xl font-bold mr-2 text-gray-700">Loans</h3>
      <router-link
        :to="{ name: 'loans' }"
        class="btn btn-sm btn-gray-soft text-credpal-200"
      >
        View Loan History
      </router-link>
    </div>

    <sm-loader v-if="isLoading" class="mb-32" />

    <div v-else-if="activeLoan && nextRepayment">
      <div class="mb-3 -mt-5">
        <div class="text-24 font-bold mr-16 text-credpal-400">
          ₦ {{ nextRepayment.amount | currency }}
        </div>
        <div
          class="text-red-500 opacity-50 text-sm"
          v-if="nextRepaymentDefaultCharge && nextRepaymentDefaultCharge > 0"
        >
          + ₦ {{ nextRepaymentDefaultCharge | currency }}
          <span class="text-xs">default charge</span>
        </div>
        <div
          class="text-green-500 opacity-50 text-sm"
          v-if="fractionalPayment && fractionalPayment > 0"
        >
          - ₦ {{ fractionalPayment | currency }}
          <span class="text-xs">Fraction Paid</span>
        </div>
        <div
          class="text-green-500 opacity-50 text-sm"
          v-if="totalProcessing && totalProcessing > 0"
        >
          - ₦ {{ totalProcessing | currency }}
          <span class="text-xs">Awaiting confirmation</span>
        </div>
        <div class="text-10 text-ash-700 mb-6">Next Repayment</div>
        <div class="inline-flex w-full mt-2 mb-5">
          <div class="alert-icon bg-green-200 text-green-500 mr-3">!</div>
          <div class="text-9 text-credpal-600">
            Your next payment is due on<br />
            {{ nextRepayment.due_date | dateFormat("M dd, Y") }}
          </div>
        </div>
        <div class="items-center">
          <button
            type="button"
            class="btn btn-blue"
            :disabled="loading"
            @click.prevent="pay(nextRepayment)"
          >
            <span>Repay Now</span>
          </button>
        </div>
      </div>
    </div>

    <div v-else-if="acceptedLoan">
      <div class="alert alert-green items-start mb-3">
        <span class="alert-icon">!</span>
        <div>
          <div class="font-bold">Terms of loan accepted</div>
          <div class="font-thin">
            You have accepted the terms and conditions of the offer letter sent
            above. Your loan will be disbursed to your account shortly.
          </div>
        </div>
      </div>
    </div>

    <div v-else-if="approvedLoan">
      <div class="alert alert-green items-start mb-3">
        <span class="alert-icon">!</span>
        <div>
          <div class="font-bold">Loan Request Approved</div>
          <div class="font-thin">
            Your loan request has been approved but you have to accept the terms
            of the loan to proceed with disbursement.
          </div>
        </div>
      </div>
      <button type="button" class="btn btn-blue" @click="viewOfferLetter">
        <span class="ml-2">View Offer Letter</span>
      </button>
    </div>

    <div v-else-if="pendingLoan">
      <div class="alert alert-blue items-start mb-3">
        <span class="alert-icon">!</span>
        <div>
          <div class="font-bold">Loan Request Sent</div>
          <div class="font-thin">
            Your loan request has been received. We are currently reviewing your
            application and will respond shortly.
          </div>
        </div>
      </div>
    </div>

    <div v-else>
      <div class="text-2xl font-bold mr-16">
        ₦ {{ standardLoanLimit | currency }}
      </div>
      <div class="text-sm text-gray-700 mb-6">Standard Loan Limit</div>
      <div class="flex flex-row items-center">
        <button
          type="button"
          class="btn btn-blue text-center"
          @click.prevent="getLoan('standard')"
        >
          <span>Request Loan</span>
        </button>
      </div>
    </div>

    <modal
      class-name="flex flex-col rounded w-full md:w-443px lg:w-443px"
      ref="loanModal"
    >
      <h4
        class="font-bold mb-16 text-gray-700 text-left text-xl md:-ml-4 xl:-ml-4 xl:text-20"
      >
        {{ formHeading }}
      </h4>

      <div
        class="mb-12 w-320px md:w-443px lg:w-443px xl:w-443px h-16 bg-ash-600 -mx-6 -mt-12 md:-mx-16 xl:-mx-16"
      >
        <div class="inline-flex px-6 mb-3 lg:px-12 pt-4">
          <span class="bg-blue-500 alert-icon text-sm text-white">
            <ion-icon name="alert-outline" />
          </span>
          <p class="text-10 px-2 text-ash-700">
            You will be charged a compulsory 1.5% management fee on your
            requested loan amount.
          </p>
        </div>
      </div>

      <form @submit.prevent="submitLoanRequest">
        <template v-if="getFormError(loanForm)">
          <div class="alert alert-red-soft -mt-6 md:-mx-4 mb-6">
            <span class="alert-icon">!</span>
            <span class="text-xs font-normal">{{
              getFormError(loanForm)
            }}</span>
          </div>
        </template>

        <div class="">
          <div class="flex justify-between">
            <div class="flex flex-col">
              <h4
                class="text-10 font-semibold md:-ml-4 xl:-ml-4 mb-4 text-left"
                style="color: #222222;"
              >
                Loan Amount
              </h4>
              <form-group
                type="money"
                class="lg:-ml-5"
                name="amount"
                :form="loanForm"
                v-model="loanForm.data.amount.value"
                :autofocus="true"
              >
              </form-group>
            </div>
            <div class="flex flex-col ml-3" v-if="loan_type == 'standard'">
              <h4
                class="text-10 font-semibold mb-4 text-left"
                style="color: #222222;"
              >
                Tenure
              </h4>
              <form-group
                name="tenure"
                :form="loanForm"
                v-model="loanForm.data.tenure.value"
                type="number"
                :autofocus="true"
              >
                {{ loanForm.data.tenure.value == 1 ? "Month" : "Months" }}
              </form-group>
            </div>
          </div>

          <div class="flex flex-row -mt-3 lg:-mx-5">
            <span
              class="bg-blue-500 h-14px inline-grid place-items-center rounded-full text-xs text-white w-14px"
            >
              <ion-icon name="alert-outline" />
            </span>
            <p class="text-10 ml-2 text-purple-200 font-semibold">
              <span v-if="loan_type === 'pay_day'">
                Pay Day Loan Limit — ₦ {{ payDayLoanLimit | currency }}
              </span>
              <span v-if="loan_type === 'standard'">
                Standard Loan Limit — ₦
                {{ standardLoanLimit | currency }}
              </span>
            </p>
          </div>

          <div class="flex flex-col lg:-mx-5 mt-12">
            <h4 class="text-2xl ml-2 text-gray-700 font-bold">
              ₦ {{ standardLoanRepaymentAmount | currency }}
              <span class="ml-2 text-10 font-normal">
                at {{ standardLoanInterestRate }}% per month
              </span>
            </h4>
            <div class="flex flex-row ml-2 mt-2">
              <span
                class="bg-blue-500 h-14px inline-grid place-items-center rounded-full text-xs text-white w-14px"
              >
                <ion-icon name="alert-outline" />
              </span>
              <p class="text-10 ml-2 text-purple-200 font-semibold">
                Monthly Repayments
              </p>
            </div>
          </div>

          <div class="flex justify-center mt-16">
            <button
              type="submit"
              class="btn btn-blue btn-md relative"
              :disabled="loanForm.loading"
            >
              <sm-loader-white v-if="loanForm.loading" />
              <template v-else>
                <span class="ml-2">Request Loan</span>
                <span
                  class="absolute inline-flex left-5 text-xl top-1/2 transform -translate-y-1/2"
                >
                  <ion-icon name="document-text-outline" />
                </span>
              </template>
            </button>
          </div>
        </div>
      </form>
    </modal>

    <payment-repayment
      :repayment="selectedRepayments"
      :payment-reference="paymentReference"
      :key="componentKey"
      @success="reloadData"
      ref="payment"
    />

    <modal
      class-name="pt-24 text-center w-full md:w-443px lg:w-443px"
      ref="existingLoanModal"
    >
      <h1 class="text-2xl font-bold mb-10">
        You can't request for a loan right now!
      </h1>

      <div class="text-sm text-gray-600 font-light mb-10">
        You currently have active loans. You have to finish repayment before
        you're able to request for another.
      </div>

      <button
        type="button"
        class="btn btn-blue btn-md"
        @click.prevent="$refs.existingLoanModal.close"
      >
        Okay
      </button>
    </modal>
  </div>
</template>

<script>
export default {
  name: "Loans",
  data() {
    return {
      loan_type: "",
      loanForm: this.$options.basicForm(
        [
          {
            name: "amount",
            rules: "required|number|min:5000"
          },
          {
            name: "tenure",
            rules: "required|number|max:12|min:1"
          }
        ],
        {
          tenures: [
            { title: "1 Month", value: 1 },
            { title: "2 Months", value: 2 },
            { title: "3 Months", value: 3 },
            { title: "4 Months", value: 4 },
            { title: "5 Months", value: 5 },
            { title: "6 Months", value: 6 }
          ]
        }
      ),
      selectedRepayments: null,
      componentKey: 0,
      loading: false
    };
  },
  computed: {
    acceptedLoan() {
      return this.loans.data?.find(loan => loan.status?.match(/accepted/i));
    },
    activeLoan() {
      return this.loans.data?.find(loan =>
        loan.status?.match(/pending|approved|accepted|disbursed|confirmed/i)
      );
    },
    approvedLoan() {
      return this.loans.data?.find(loan => loan.status === "approved");
    },
    formHeading() {
      return this.loan_type == "pay_day"
        ? "Request a Pay-Day Loan"
        : "Request a Standard Loan";
    },
    isLoading() {
      return this.repayments.loading || this.loans.loading;
    },
    loans() {
      return this.resources.loans;
    },
    loanRequestQuery() {
      return this.$route.query.loanRequest;
    },
    repayments() {
      return this.resources.repayments;
    },
    nextRepayment() {
      return this.repayments.data?.[0];
    },
    nextRepaymentDefaultCharge() {
      return this.nextRepayment?.default_charge?.amount || 0;
    },
    paymentReference() {
      const date = new Date().format("dd/mm/Y");
      const random = Math.round(Math.random() * 100000);

      return `${this.user?.name}—${date}-${random}`;
    },
    pendingLoan() {
      return this.loans.data?.find(loan => loan.status === "pending");
    },
    standardLoanInterestRate() {
      return this.user?.profile?.standard_loan_interest_rate || 0;
    },
    standardLoanLimit() {
      return this.user?.profile?.standard_loan_limit || 0;
    },
    standardLoanRepaymentAmount() {
      const tenure = this.loanForm.data.tenure.value || 0;
      const inputedAmount = this.loanForm.data.amount.value;
      const interest = (this.standardLoanInterestRate / 100) * inputedAmount;
      const amount = inputedAmount / tenure;
      const checkAmount = isFinite(amount + interest);
      if (!checkAmount) {
        return 0;
      }
      return amount + interest;
    },
    fractionalPayment() {
      return this.nextRepayment?.repayment_wallet?.total_paid || 0;
    },
    totalProcessing() {
      return this.nextRepayment?.totalProcessingAmount || 0;
    }
  },
  mounted() {
    this.requestLoanIfRequired();
    this.reloadData();
  },
  updated() {
    this.requestLoanIfRequired();
  },
  methods: {
    getLoan(loan_type) {
      // this.googleEvent('Loan request', 'request_loan')
      if (!this.validateUser("onboarding")) {
        return this.resumeOnboarding();
      }

      if (this.activeLoan) {
        return this.$refs.existingLoanModal.open();
      }

      this.loan_type = loan_type;
      // this.$refs.helpModal.close();
      this.$refs.loanModal.open();

      const limit =
        loan_type == "pay_day" ? "payDayLoanLimit" : "standardLoanLimit";
      this.loanForm.data.amount.rules = `required|number|min:5000|max:${this[limit]}`;
      this.loanForm.data.tenure.rules =
        loan_type == "pay_day" ? "nullable" : "required|number|min:1|max:12";
    },
    pay(repayment) {
      //this.googleEvent('Loan repayment', 'repay_now')
      this.componentKey += 1;
      this.repayment = repayment;
      this.selectedRepayments = repayment;
      this.$nextTick(() => this.$refs.payment.open());
    },
    payAll() {
      this.selectedRepayments = this.repayments.data.filter(
        repayment => !repayment.status.match(/paid|transferred/)
      );
      this.$refs.payment.open();
    },
    requestLoanIfRequired() {
      if (this.loanRequestQuery) {
        this.getLoan("standard");
      }
    },
    viewOfferLetter() {
      if (!this.approvedLoan) return;
      this.$router.push({
        name: "offer-letter",
        params: { loanId: this.approvedLoan.id }
      });
    },

    async getLoans(forced = false) {
      this.loadResource("loans", forced);
    },
    async getRepayments(forced = false) {
      this.loadResource("repayments", forced);
    },
    async reloadData() {
      await this.getLoans(true);
      this.getRepayments(true);
    },
    async submitLoanRequest() {
      //this.googleEvent('Loan request', 'loan_requested')
      if (!this.validateForm(this.loanForm)) {
        return false;
      }

      this.loanForm.loading = true;

      await this.sendRequest("personal.loans.request", {
        data: {
          amount: this.loanForm.data.amount.value,
          tenure: this.loanForm.data.tenure.value,
          loan_type: this.loan_type,
          user_id: this.user.id
        },
        success: () => {
          this.$success({
            title: "Loan Request Submitted",
            body:
              "Your request will be reviewed and you will be notified of any changes.",
            button: "Okay"
          });
          this.loanForm = this.resetForm(this.loanForm);
          this.$refs.loanModal.close();
          this.reloadData();
        },
        error: error => {
          this.loanForm.error = error;
          this.mapFormErrors(error.response?.data?.errors);
        }
      });

      this.loanForm.loading = false;
    }
  }
};
</script>
